<template>
    <div>
      <h1>Welcome to {{ welcomeMessage }}</h1>
      <router-link to="/">Go Home</router-link>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'WelcomePage',
    data() {
      return {
        welcomeMessage: '123',
      };
    },
    created() {
      this.fetchWelcomeMessage();
    },
    methods: {
      async fetchWelcomeMessage() {
        try {
          const response = await axios.get('http://localhost:3000');
          this.welcomeMessage = response.data.welcome; // 假设 API 返回 "content" 字段
        } catch (error) {
          console.error('Error fetching welcome message:', error);
        }
      },
    },
  }
  </script>
  
  <style scoped>
  </style>