<template>
    <div>
      <h1>Welcome</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
  }
  </script>
  
  <style scoped>
  </style>